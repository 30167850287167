import {Pagination, Table} from "react-bootstrap";
import emptyImage from "../../assets/image/empty.png";
import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as FootballIcon} from '../../assets/icons/fußball.svg';
import winner from "../../assets/image/winner.png";
import AddSuggestionModal from "./AddSuggestionModal";
import {AuthContext} from "../../context/AuthContext";
import axios from "axios";

const CurrentGamesTable = ({gamesWithoutResult, combinedData, getAllSuggestions, suggestions}) => {
    const {activeUser} = useContext(AuthContext);
    const [selectedGame, setSelectedGame] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const gamesPerPage = 6;
    const [sortByEndDate, setSortByEndDate] = useState(true);
    const [users, setUsers] = useState([]);
    const sortedUsers = users.sort((a, b) => b.sumScore - a.sumScore);


    /*** Sortierung der Spiele nach Enddatum ***/
    const sortedGames = [...gamesWithoutResult].sort((a, b) => {
        return sortByEndDate ? new Date(a.endDate) - new Date(b.endDate) : new Date(b.endDate) - new Date(a.endDate);
    });

    /*** Pagination ***/
    const indexOfLastGame = currentPage * gamesPerPage;
    const indexOfFirstGame = indexOfLastGame - gamesPerPage;
    const currentGames = sortedGames.slice(indexOfFirstGame, indexOfLastGame);

    /*** Anzahl der Seiten berechnen ***/
    const pageNumbers = Math.ceil(sortedGames.length / gamesPerPage);

    const openSuggestionModal = (game) => {
        setShowModal(true);
        setSelectedGame(game);
    };
    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        try {
            const response = await axios.get('https://backend.atkfacility.de/users');
            const sortedUsers = response.data.sort((a, b) => {
                return b.score.length - a.score.length;
            });
            setUsers(sortedUsers);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>

            <div class="row">
                <img className="col-6 winnerImage" src={winner} alt="Empty"/>
                <div className="col-6 listOfRanking">
                    {sortedUsers.slice(3).map((user, index) => (
                        <p className="listElement" key={index + 3}>
                            <p>{index + 4} {user.name}</p>
                            <p className="scores">{user.sumScore}</p>
                        </p>
                    ))}

                </div>
            </div>


            { /*
                        {currentGames.length > 0 ? (
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Spieldatum</th>
                        <th>Spielzeit</th>
                        <th>Spiel</th>
                        <th>Dein Einsatz</th>
                        <th>Punktzahl</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentGames.map((game, index) => (
                        <tr key={index}>
                            <td>{game.formattedEndDate}</td>
                            <td>{game.countdown}</td>
                            <td>{game.gameName}</td>
                            <td>{game.suggestion}</td>
                            <td>
                                <div className={`resultColumn ${game.suggestion ? 'pending' : 'offen'}`}>
                                    {game.suggestion ? 'pending' : 'offen'}
                                </div>
                            </td>
                            <td style={{width: "200px"}}>
                                <button
                                    className={`editButton ${game.countdown ? 'active' : 'inactive'}`}
                                    onClick={() => openSuggestionModal(game)}
                                    disabled={!game.countdown}
                                >
                                    <FootballIcon/> <p>Jetzt tippen!</p>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                    <tr className="table-hover">
                        <td colSpan="12">
                            <Pagination>
                                {Array.from({length: pageNumbers}, (_, index) => (
                                    <Pagination.Item key={index + 1} onClick={() => setCurrentPage(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </td>
                    </tr>
                    </tfoot>
                </Table>
            ) : (
                <div className="placeholder-container">
                    <div className="placeholder">
                        <img src={emptyImage} alt="Empty"/>
                        <h3>Leider gibt es aktuell keine Spiele</h3>
                    </div>
                </div>
            )}*/

            }


            <AddSuggestionModal
                show={showModal}
                handleClose={handleClose}
                suggestions={suggestions}
                selectedGame={selectedGame}
                userEmail={activeUser?.email}
                updateSuggestions={getAllSuggestions}
            />
        </>
    );
};

export default CurrentGamesTable;
