import React, {useContext, useEffect, useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const AddSuggestionModal = ({ show, handleClose, selectedGame, suggestions, userEmail, updateSuggestions }) => {
    const { activeUser } = useContext(AuthContext);
    const [teamASuggestion, setTeamASuggestion] = useState(0);
    const [teamBSuggestion, setTeamBSuggestion] = useState(0);

    useEffect(() => {
        const existingSuggestion = suggestions.find(suggestion => suggestion.userEmail === userEmail && suggestion.game_id === selectedGame?.game_id);
        if (existingSuggestion) {
            setTeamASuggestion(existingSuggestion.suggestion.teamA);
            setTeamBSuggestion(existingSuggestion.suggestion.teamB);
        } else {
            setTeamASuggestion(0);
            setTeamBSuggestion(0);
        }
    }, [suggestions, userEmail, selectedGame]);

    const saveSuggestion = async () => {
        const existingSuggestion = suggestions.find(suggestion => suggestion.userEmail === userEmail && suggestion.game_id === selectedGame?.game_id);
        const editedSuggestion = {
            game_id: existingSuggestion ? existingSuggestion.game_id : selectedGame.game_id,
            gameName: existingSuggestion ? existingSuggestion.gameName : selectedGame.gameName,
            userEmail: userEmail,
            user_id: activeUser._id,
            suggestion: {
                teamA: teamASuggestion,
                teamB: teamBSuggestion,
            },
            score: existingSuggestion ? existingSuggestion.score : false
        };
        try {
            if(activeUser){
                if (existingSuggestion) {
                    await axios.put(`https://backend.atkfacility.de/guess/${existingSuggestion._id}`, editedSuggestion);
                } else {
                    await axios.post('https://backend.atkfacility.de/guess', editedSuggestion);
                }
                handleClose();
                updateSuggestions();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title> {selectedGame?.gameName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row" >
                    <Form.Group controlId="formTeamA" className="pb-24 col-6">
                        <Form.Label>{selectedGame?.teamA}</Form.Label>
                        <Form.Control
                            disabled={selectedGame.result}
                            type="number"
                            min="0"
                            value={teamASuggestion}
                            onChange={(e) => setTeamASuggestion(Number(e.target.value))}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTeamB" className="pb-24 col-6">
                        <Form.Label>{selectedGame?.teamB}</Form.Label>
                        <Form.Control
                            disabled={selectedGame.result}
                            min="0"
                            type="number"
                            value={teamBSuggestion}
                            onChange={(e) => setTeamBSuggestion(Number(e.target.value))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={saveSuggestion} disabled={selectedGame.result}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSuggestionModal;
