import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Table, Pagination, Tab, Tabs} from "react-bootstrap";
import AddGameModal from "./AddGameModal";
import {ReactComponent as RefreshIcon} from '../../assets/icons/refresh.svg';
import {AuthContext} from "../../context/AuthContext";
import emptyImage from '../../assets/image/empty.png';
import CurrentGamesTable from "./CurrentGamesTable";
import addGameModal from "./AddGameModal";

const GameTable = () => {
    const [games, setGames] = useState([]);
    let [users, setUsers] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showAddGameModal, setShowAddGameModal] = useState(false);

    const [currentPageArchive, setCurrentPageArchive] = useState(1);
    const [sortByEndDate, setSortByEndDate] = useState(true);
    const {activeUser} = useContext(AuthContext);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [activeTab, setActiveTab] = useState("games");

    const formatCountdown = (seconds) => {
        const units = [
            {label: 'Tage', value: 3600 * 24},
            {label: ':', value: 3600},
            {label: ':', value: 60},
            {label: '', value: 1}
        ];

        let formattedCountdown = units.reduce((acc, unit) => {
            const count = Math.floor(seconds / unit.value);
            if (count > 0) {
                acc.push(`${count} ${unit.label}`);
                seconds -= count * unit.value;
            }
            return acc;
        }, []).join(' ');

        return formattedCountdown || '0s';
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleAddGameModalClose = () => {
        setShowAddGameModal(false);
    };
    const openAddGameModal = () => {
        setShowAddGameModal(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getAllGames();
                await getAllSuggestions();
                await getAllUsers();

            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);

    const getAllGames = async () => {
        try {
            const response = await axios.get('https://backend.atkfacility.de/games');
            setGames(response.data);
        } catch (error) {
            console.error("Error fetching games:", error);
        }
    };
    const getAllSuggestions = async () => {
        try {
            const response = await axios.get('https://backend.atkfacility.de/guess');
            setSuggestions(response.data);
        } catch (error) {
            console.error("Error fetching suggestions:", error);
        }
    };
    const getAllUsers = async () => {
        try {
            const response = await axios.get('https://backend.atkfacility.de/users');
            const sortedUsers = response.data.sort((a, b) => {
                return b.score.length - a.score.length;
            });
            setUsers(sortedUsers);
        } catch (error) {
            console.error(error);
        }
    };

    const sortGamesByEndDate = (games) => {
        return games.slice().sort((a, b) => {
            return sortByEndDate ? new Date(a.endDate) - new Date(b.endDate) : new Date(b.endDate) - new Date(a.endDate);
        });
    };

    const gamesWithResult = [];
    const gamesWithoutResult = [];

    const combinedData = games.map(game => {
        const userSuggestion = suggestions.find(suggestion => suggestion.game_id === game.game_id && suggestion.userEmail === activeUser?.email);
        const user = users.find(user => user.email === activeUser?.email);
        let userScores = user?.score || [];
        if (!Array.isArray(userScores)) {
            userScores = [];
        }
        const endTime = new Date(game.endDate);
        const timeDiff = endTime.getTime() - currentTime.getTime();
        const secondsRemaining = Math.floor(timeDiff / 1000);
        const formattedEndDate = endTime.toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric'});

        return {
            game_id: game.game_id,
            gameName: game.gameName,
            teamA: game.teamA,
            teamB: game.teamB,
            suggestion: userSuggestion ? `${userSuggestion.suggestion.teamA}:${userSuggestion.suggestion.teamB}` : undefined,
            endDate: game.endDate,
            formattedEndDate: formattedEndDate,
            score: userScores.map(x => x.game_id === game.game_id ? (x.score >= 0 ? x.score : 'undefined') : 0),
            countdown:
                secondsRemaining < 3600 && secondsRemaining >= 0
                    ? formatCountdown(secondsRemaining)
                    : endTime < new Date()
                        ? null
                        : endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' Uhr',
            result: game.result
        };
    });

    const saveScoreSum = async (newValue, user) => {
        try {
            const response = await axios.put(`https://backend.atkfacility.de/users/${user?.email}`, {
                score: user.score,
                sumScore: user.sumScore + newValue
            });
            console.log('Score updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating score:', error);
        }
    }

    const calculateSumScoreForAllUser = () => {
        games.map(game => {
            if (game.result) {
                const filteredSuggestionsForFinishedGames = suggestions?.filter(x => x.game_id === game.game_id && game.result);
                users.forEach((user) => {
                    filteredSuggestionsForFinishedGames.forEach((suggestion) => {
                        if (suggestion.user_id === user._id) {
                            const gameIdExist = user.score.length > 0 && user.score?.find(s => s.game_id === game.game_id);
                            if (!gameIdExist) {
                                if (game.result.teamA === suggestion.suggestion.teamA && game.result.teamB === suggestion.suggestion.teamB) {
                                    user.score.push({game_id: game.game_id, score: 4});
                                    saveScoreSum(4, user);
                                } else if ((game.result.teamB - game.result.teamA) === (suggestion.suggestion.teamB - suggestion.suggestion.teamA)) {
                                    user.score.push({game_id: game.game_id, score: 3});
                                    saveScoreSum(3, user);
                                } else if (
                                    ((game.result.teamA > game.result.teamB) && (suggestion.suggestion.teamA > suggestion.suggestion.teamB)) ||
                                    ((game.result.teamA < game.result.teamB) && (suggestion.suggestion.teamA < suggestion.suggestion.teamB)) ||
                                    ((game.result.teamA === game.result.teamB) && (suggestion.suggestion.teamA === suggestion.suggestion.teamB))
                                ) {
                                    user.score.push({game_id: game.game_id, score: 2});
                                    saveScoreSum(2, user);
                                } else {
                                    user.score.push({game_id: game.game_id, score: 0});
                                    saveScoreSum(0, user);
                                }
                            }
                        }
                    });
                });
            }
        });
    }

    combinedData.forEach(data => {
        if (data.result) {
            gamesWithResult.push(data);
        } else {
            gamesWithoutResult.push(data);
        }
    });

    const getScoreForUserByGameId = (game) => {
        const scoreEntry = activeUser.score.find(score => score.game_id === game.game_id);
        return scoreEntry ? scoreEntry.score : null;
    }

    const [gamesPerPage] = useState(6);
    const indexOfLastGameArchive = currentPageArchive * gamesPerPage;
    const indexOfFirstGameArchive = indexOfLastGameArchive - gamesPerPage;
    const currentGamesWithResult = sortGamesByEndDate(gamesWithResult).slice(indexOfFirstGameArchive, indexOfLastGameArchive);
    const pageNumbersArchive = [];
    for (let i = 1; i <= Math.ceil(gamesWithResult.length / gamesPerPage); i++) {
        pageNumbersArchive.push(i);
    }

    const changeTab = (selectedTab) => {
        setActiveTab(selectedTab);
    }

    return (
        <div className="container">
            <Tabs activeKey={activeTab} id="gameTabs"
                  onSelect={changeTab}>
                <Tab eventKey="games" title="Spiele">
                    <CurrentGamesTable gamesWithoutResult={gamesWithoutResult}
                                       combinedData={combinedData}
                                       suggestions={suggestions}
                                       getAllSuggestions={getAllSuggestions}
                    ></CurrentGamesTable>
                </Tab>
                <Tab eventKey="ranking" title="Rankingliste">
                    <div className="col-12 updateButton">
                        <button onClick={calculateSumScoreForAllUser}><RefreshIcon /> <p>Aktualisiere Ergebnisse</p></button>
                    </div>
                    <div className="rankingTable">
                        <Table responsive>
                            <thead className="sticky-header">
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Punkte</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.sort((a, b) => b.sumScore - a.sumScore)
                                .map((user, index) => (
                                    <tr key={index}>
                                        <td style={{ width: 0 }}>
                                            {index === 0 ? '🥇' : ''}
                                            {index === 1 ? '🥈' : ''}
                                            {index === 2 ? '🥉' : ''}
                                        </td>
                                        <td>{user.name}</td>
                                        <td>{user.sumScore}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Tab>
                <Tab eventKey="archive" title="Abgeschlossene Spiele">
                    {currentGamesWithResult.length > 0 ? (
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Spiel</th>
                                <th>Dein Einsatz</th>
                                <th>Das Ergebnis</th>
                                <th>Punktzahl</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentGamesWithResult.map((game, index) => (
                                <tr key={index}>
                                    <td>{game.gameName}</td>
                                    <td>{game.suggestion}</td>
                                    <td>{game.result.teamA + ':' + game.result.teamB}</td>
                                    <td>
                                        <div
                                            className={`resultColumn ${getScoreForUserByGameId(game) === null ? 'zeroPoint' : 'win'}`}>
                                            {getScoreForUserByGameId(game)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan="5">
                                    <Pagination>
                                        {pageNumbersArchive.map((number) => (
                                            <Pagination.Item key={number} onClick={() => setCurrentPageArchive(number)}>
                                                {number}
                                            </Pagination.Item>
                                        ))}
                                    </Pagination>
                                </td>
                            </tr>
                            </tfoot>
                        </Table>
                    ) : (
                        <div className="placeholder-container">
                            <div className="placeholder">
                                <img src={emptyImage} alt="Empty"/>
                                <h3>Es sind noch keine Spiele abgeschlossen</h3>
                            </div>
                        </div>
                    )}
                </Tab>
            </Tabs>
            <AddGameModal
                show={showAddGameModal}
                handleClose={handleAddGameModalClose}
                allGames={games}
                updateGames={getAllGames}
            />
        </div>
    );
};

export default GameTable;
