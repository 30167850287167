import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import {baseUrl} from "../config";
import {AuthContext} from "../context/AuthContext";


function Register() {
    const [cookies] = useCookies(["jwt"]);
    const [cook, setCook] = useCookies([]);
    const { setUserId } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (cookies.jwt) {
            const storedUserData = cook.userData;
            setUserId(storedUserData);
            navigate("/home");
        }
    }, [cookies, navigate]);

    const [values, setValues] = useState({ name: "", email: "", password: "" });

    const generateError = (error) =>
        toast.error(error, {
            position: "bottom-right",
        });

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const { data } = await axios.post(
                "https://backend.atkfacility.de/api/auth/register",
                values,
                { withCredentials: true }
            );
            if (data) {
                setCook('userData', JSON.stringify(data['user']), { path: '/' });
                setUserId(data['user']);
                if (data.errors) {
                    const { name, email, password } = data.errors;
                    if (email) generateError(name);
                    if (name) generateError(name);
                    if (password) generateError(password);
                } else {
                    navigate("/home");
                }
            }
        } catch (ex) {
            if (ex.response && ex.response.data && ex.response.data.errors) {
                const { name, email, password } = ex.response.data.errors;
                if (name) generateError(name);
                if (email) generateError(email);
                if (password) generateError(password);
            }
        }
    };


    return (
        <div className="home-container background-image">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="login">
                        <h1>Jetzt Registrieren!</h1>
                        <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder="Benutzername"
                                        onChange={(e) =>
                                            setValues({...values, [e.target.name]: e.target.value})
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Email"
                                        onChange={(e) =>
                                            setValues({...values, [e.target.name]: e.target.value})
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        placeholder="Passwort"
                                        className="form-control"
                                        name="password"
                                        onChange={(e) =>
                                            setValues({...values, [e.target.name]: e.target.value})
                                        }
                                    />
                                </div>
                            <button type="submit" className="btn btn-primary saveButton">Registrieren</button>
                            <span className="switchButton">
                    Du hast bereits ein Account? <Link to="/login">Login</Link>
                </span>
                        </form>
                        <ToastContainer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
