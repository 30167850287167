import React, {useContext, useRef, useState} from "react";
import logo from '../assets/logo_tipi.png';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from '../../src/assets/icons/logout.svg';
import ProfileModal from "./ProfileModal";

export default function Navigation() {
    const [showProfileModal, setShowProfileModal] = useState(false);

    const logoRef = useRef(null);
    const { activeUser, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate("/login");
    };

    const openEditProfileModal = () => {
        setShowProfileModal(true);
    };

    const handleCloseEditProfileModal = () => {
        setShowProfileModal(false);
    }

    return (
        <>
            <div className="userInfo">
                <button onClick={openEditProfileModal}><p className="description">Edit Profile</p></button>
                <button onClick={handleLogout}><LogoutIcon/> <p className="description">Logout</p></button>
            </div>

            <div className="content center">
                <div>
                    <img ref={logoRef} src={logo} alt="Logo" className="logo"/>
                    <h6 className="welcome">Hi {activeUser.name}!</h6>
                </div>
            </div>

            <ProfileModal handleClose={handleCloseEditProfileModal} show={showProfileModal}></ProfileModal>
        </>
    );
}
