import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from "axios";

const AddSuggestionModal = ({ show, handleClose, allGames, updateGames}) => {
    const [gameName, setGameName] = useState('');
    const [teamA, setTeamA] = useState('');
    const [teamB, setTeamB] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime , setEndTime] = useState('');

    const saveGame = async () => {
        const newGame = {
            game_id: allGames.length + 1,
            gameName: gameName,
            teamA: teamA,
            teamB: teamB,
            endDate: endDate,
            time: endTime,
        }
        try {
            await axios.post(`https://backend.atkfacility.de/games`, newGame)
                .then(res => console.log(res));
            handleClose();
            updateGames()
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Erstelle ein Spiel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="row">
                        <Form.Group controlId="gameName" className="col-12 pb-24">
                            <Form.Label>Spielname</Form.Label>
                            <Form.Control
                                type="string"
                                value={gameName}
                                onChange={(e) => setGameName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formTeamA" className="col-6 pb-24">
                            <Form.Label>Team A</Form.Label>
                            <Form.Control
                                type="string"
                                value={teamA}
                                onChange={(e) => setTeamA(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formTeamB" className="col-6 pb-24">
                            <Form.Label>Team B</Form.Label>
                            <Form.Control
                                type="string"
                                value={teamB}
                                onChange={(e) => setTeamB(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="endDate" className="col-12 pb-24">
                            <Form.Label>Laufzeit</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={saveGame}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSuggestionModal;
