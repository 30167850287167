import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [userId, setUserId] = useState(null);
    const [activeUser, setActiveUser] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies([]);

    useEffect(() => {
        if (cookies) {
            setUserId(cookies.userData)
        }
    }, [cookies]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                if (userId) {
                    const response = await axios.get(`https://backend.atkfacility.de/users/${userId}`);
                    setActiveUser(response.data);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        fetchUser();
    }, [userId]);

    const logout = () => {
        removeCookie('userData', { path: '/' });
        removeCookie('jwt', { path: '/' });
        setUserId(null);
        setActiveUser(null);
    };


    return (
        <AuthContext.Provider value={{ userId, setUserId, activeUser, setActiveUser,  logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
