import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const ProfileModal = ({ show, handleClose }) => {
    const { activeUser, setActiveUser } = useContext(AuthContext);

    const [name, setUserName] = useState(activeUser?.name || '');

    useEffect(() => {
        setUserName(activeUser?.name || '');
    }, [activeUser]);

    const saveUser = async () => {
        try {
            await axios.put(`https://backend.atkfacility.de/users/${activeUser?.email}`, {
                name: name,
            });
            console.log('***hir');
            setActiveUser({ ...activeUser, name: name });
            handleClose();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleModalClose = async () => {
        if (name !== activeUser?.name) {
            await saveUser();
        } else {
            handleClose();
        }
    };

    return (
        <Modal show={show} onHide={handleModalClose}>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name" className="col-12 pb-24">
                        <Form.Label>Benutzername</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={saveUser}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfileModal;
