import { ToastContainer} from "react-toastify";
import Navigation from "./Navigation";
import GameTable from "./games/GameTable";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";
import Login from "./Login";

export default function Home() {
    const { activeUser } = useContext(AuthContext);

    return (
        <>
            {activeUser ? (
                <div>
                    <Navigation />
                    <GameTable />
                    <ToastContainer />
                </div>
            ) : (
                <Login/>
            )}
        </>
    );

}

